.header {
    margin-bottom: 8px;
    h1 {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 8px;
    }
    p {
        font-size: 1.4rem;
        color: red;
    }
}
.content {
    margin-bottom: 16px;
}
.button {
    justify-content: center;
    width: 100%;
    height: 48px;
    * {
        font-size: 20px;
        font-weight: 700;
    }
}
.time {
    display: flex;
    margin-top: 8px;
    time {
        color: red;
    }
}
