.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

    line-height: 30px;
    color: var(--text-color);
    height: 100%;
    background-color: var(--background-color);
    border: 2px dashed var(--color-disable);
    border-radius: 16px;
}
.img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
}
.btn-close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    border: none;
    outline: none;
    height: 30px;
    background-color: red;
    border-radius: 0 16px 0 0;
}
