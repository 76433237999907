//Logo
.wraper {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    background-color: var(--background-color);
    box-shadow: none;
    z-index: 100;
    border-bottom: 1px solid var(--color-disable);
    //transition: all ease 0.5s;
}

.header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 260px;
    padding: 10px;
    img {
        width: 56px;
        height: 56px;
    }

    .header-title {
        padding-left: 5px;
        font-size: 3.2rem;
        font-weight: 600;
    }
}
.toogle-menu {
    display: none;

    * {
        font-size: 3rem;
    }
}

// Search
.search {
    display: flex;
    width: 300px;
    font-size: 14px;
    font-weight: 400;
    border: 2px solid var(--color-disable);
    overflow: hidden;
    padding-right: 10px;
    border-radius: 8px;
    background-color: var(--background-color);

    &:hover {
        border-color: var(--text-color);
    }
    &:focus-within {
        border: 2px solid var(--primary);
    }

    input {
        flex: 1;
        padding: 8.5px 0 8px 14px;
    }
    .icon {
        text-align: center;
        width: 35px;
        height: 35px;

        cursor: pointer;
        line-height: 32px;
        svg {
            color: var(--primary);
        }
    }
}

// NavBar
.nav-bar {
    display: flex;
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    &:hover {
        color: var(--primary);
    }
    .active {
        color: var(--primary);
    }
}

// Action
.action {
    display: flex;
    user-select: none;
    align-items: center;
}

// Action login
.account {
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    margin-left: 16px;
    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    .menu {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-left: 5px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}
.menu-box {
    background-color: var(--background-color);
    width: 230px;
    padding: 8px 0px;
    border-radius: 4px;
    color: var(--text-color);
    box-shadow: var(--box-shadow);
}

.account_info {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    .name {
        font-weight: 600;
    }
    .role {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 600px) {
    .toogle-menu {
        display: flex;
    }
    .create-exam {
        display: none;
    }
    .account_info {
        display: none;
    }
    .search {
        display: none;
        &.searchMobile {
            display: flex;
        }
        position: absolute;
        top: 75px;
        left: calc(50% - 150px);
    }
    .nav-bar {
        display: none;
    }
    .wraper {
        padding: 0 5px;
        .header-title {
            font-size: 2.4rem;
        }
        .header-logo {
            padding: 10px 5px;
            min-width: 230px;
        }
    }
    .register {
        display: none;
    }
    .action {
        .login {
            margin: 0 4px;
            padding: 4px 8px;
            div {
                display: none;
            }
            span {
                margin: 0;
            }
        }
    }
}
