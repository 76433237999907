.change-wraper {
    margin-top: 6px;
    padding: 0 16px;
}
.btn-change {
    justify-content: center;
    width: 100%;
    height: 48px;
    * {
        font-size: 20px;
        font-weight: 700;
    }
}
.header {
    margin-bottom: 13px;
    h1 {
        text-align: center;
        font-size: 2.4rem;
        padding-bottom: 8px;
    }
    .name {
        text-align: center;
    }
}
