.wraper {
    display: flex;
    flex-direction: column;
}
.input-group {
    position: relative;
    border: 1px solid var(--color-disable);
    border-radius: 8px;
    width: 100%;
    &:focus-within {
        border-color: var(--primary);
    }
    &.red {
        border-color: red;
    }
    input {
        width: 100%;
        border-radius: 8px;
    }
    select {
        width: 100%;
        border-radius: 8px;
        border: none;
        outline: none;
    }
    input,
    select {
        color: var(--text-color);
        background-color: var(--background-color);
        padding: 16px;
    }

    .label {
        color: var(--text-color);
        display: inline-block;
        height: 20px;
        position: absolute;
        top: -10px;
        left: 17px;
        padding: 0 8px;
        z-index: 1;
        font-size: inherit;
        //background: green;
    }
    .label::before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 1px);
        width: 100%;
        height: 1px;
        background-color: var(--background-color);
        z-index: -1;
    }
}
.core {
    //
    display: flex;
    align-items: center;
    width: 100%;
}
.err-mesage {
    padding: 3px 5px;
    font-size: 1.2rem;
    height: 18px;
    color: red;
}
