.wraper {
    margin-top: 70px;
}
.sidebar {
    display: none;
}
@media only screen and (max-width: 600px) {
    .sidebar {
        display: block;
    }
}
