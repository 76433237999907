.wraper {
    padding-bottom: 112px;
    padding-top: 72px;
    color: var(--text-color);
}
.container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
}
.wraper-logo {
    width: 580px;
    padding-right: 32px;
    h2 {
        font-size: 2.8rem;
        font-weight: 400;
    }
}
.logo-group {
    padding: 112px 0 16px;
    .btn-home {
        height: 100%;
    }
    .core {
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
            width: 56px;
            height: 56px;
            margin-right: 16px;
        }
        h1 {
            font-size: 3.2rem;
        }
    }
}
.content {
    margin: 40px 0 0;
    width: 396px;
    background-color: var(--background-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    padding-bottom: 24px;
    padding-top: 10px;
    > div {
        padding: 16px 16px 0 16px;
    }
    hr {
        margin: 20px 16px;
    }
}
// .input {
//     padding: 6px 0;
//     input {
//         width: 100%;
//         padding: 14px 16px;
//         border: 1px solid #dddfe2;
//         border-radius: 6px;
//         color: var(--text-color);
//         &:focus {
//             border-color: var(--primary);
//         }
//     }
// }
// .login-wraper {
//     margin-top: 6px;
//     padding: 0 16px;
// }
// .btn-login {
//     justify-content: center;
//     width: 100%;
//     height: 48px;
//     * {
//         font-size: 20px;
//         font-weight: 700;
//     }
// }

// .forgot-pwr {
//     text-align: center;
//     margin-top: 16px;
// }
// .register-wraper {
//     display: flex;
//     justify-content: center;
//     padding: 6px 0 0;
// }
.btn-register {
    height: 48px;
    background-color: #42b72a;
    &:hover {
        background-color: #36a420;
    }
    * {
        font-size: 1.7rem;
        font-weight: 600;
    }
}
@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .wraper-logo {
        text-align: center;
        font-size: 2.4rem;
        width: 350px;
        padding: 0;
        h2 {
            padding: 0 8px;
        }
    }
    .logo-group {
        display: flex;
        justify-content: center;
        padding-top: 0;
    }
    .wraper {
        padding: 0;
    }
    .content {
        width: 350px;
    }
}
