.wrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    overflow: hidden;
}

// .box {
//     background-color: var(--background-color);

// }

.message {
    transition: flex-grow 0.5s ease;
    flex: 2;
    border-right: 1px solid var(--color-disable);
}
.conversation {
    transition: flex-grow 0.5s ease;
    flex: 1;
}

@media only screen and (max-width: 600px) {
    .wrapper {
        position: relative;
        display: block;
        padding: 0;
    }
    .message {
        visibility: hidden;
        position: absolute;
        inset: 0;
        border-right: none;
        z-index: -1;
        &.open {
            visibility: visible;
            z-index: 10;
            animation: fadeInUp 0.3s ease forwards;
        }
    }
    .conversation {
        // z-index: 10;
        // &.open {
        //     z-index: 9;
        // }
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
