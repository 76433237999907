.wraper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    .image-blur {
        display: block;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        filter: brightness(0.5) blur(20px);
        object-fit: cover;
    }
}
.banner {
    position: relative;
    .banner-image {
        width: 1090px;
        height: 350px;
        object-fit: cover;
    }
    .button-banner {
        position: absolute;
        bottom: 10px;
        right: 5px;
    }
}
.user {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: -70px;
    left: 40px;
}
.user-avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar {
        border: 10px solid whitesmoke;
        width: 154px;
        height: 154px;
        border-radius: 50%;
        object-fit: cover;
    }
    .button {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
    }
}
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .image-avatar {
        width: 300px;
        height: 300px;
    }
    .image-banner {
        width: 500px;
        height: 175px;
    }
    .input-name {
        height: 44px;
        width: 250px;
        padding: 8px;
        margin-bottom: 8px;
        border-radius: 8px;
        border: 1px solid black;
    }
}

.user-name {
    position: relative;
    font-size: 2.8rem;
    font-weight: 700;
    margin-left: 16px;
    margin-bottom: 16px;
    text-align: center;
    color: var(--text-color);
    span {
        font-size: inherit;
    }
    .btn-name {
        position: absolute;
        right: -60px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.action {
    height: 90px;
}
.content {
}

@media only screen and (max-width: 600px) {
    .banner {
        width: 100%;
        .banner-image {
            width: 100%;
            height: 230px;
            object-fit: cover;
        }
        .button-banner {
            position: absolute;
            bottom: 10px;
            right: 5px;
            transform: scale(0.8);
        }
    }
    .user {
        flex-direction: column;
        align-items: center;
        bottom: -110px;
        left: 50%;
        transform: translateX(-50%);
    }
    .user-avatar {
        transform: scale(0.8);
        .button {
            right: 5px;
            bottom: 0;
        }
    }
    .user-name {
        transform: scale(0.8);
    }
    .popup {
        .image-banner {
            max-width: 300px;
            height: 150px;
        }
    }
    .action {
        height: 100px;
    }
}
