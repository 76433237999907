.box {
    color: var(--text-color);
    border-radius: 8px;
    background-color: var(--background-color);
    padding: 16px;
    margin: 16px 16px 16px 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 10px 4px;
    height: auto;
}
.wrapper {
    color: var(--text-color);
    //transition: all ease 0.5s;
}
.info {
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 36px 16px 16px 16px;
    align-items: center;
    .header {
        position: absolute;
        left: 4px;
        top: 4px;
    }
    .image {
        width: 310px;
        height: 174px;
    }
}
// Header
.container {
    display: flex;
    width: 100%;
}

.content {
    width: 70%;
    padding: 16px 24px;
    .textarea {
        font-family: 'Inter', sans-serif;
        background-color: var(--background-color);
        color: var(--text-color);
        width: 100%;
        height: 150px;
        border-radius: 8px;
        padding: 8px 16px;
        resize: none;
        outline: none;
    }
    .question {
        //padding: 0 0 0 16px;
        margin-bottom: 8px;
    }
}

.example-table {
    padding: 16px 0;
    width: 475px;
    max-width: 500px;
    height: 300px;
    .part {
        display: flex;
    }
    .question {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 300px;

        overflow-y: auto;
    }
    .button {
        min-width: 62px;
        justify-content: center;
        margin: 3px;
    }
}
.action {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
.popup-warning {
    display: flex;
    justify-content: end;
}
