.contest {
    width: 670px;
    height: auto;
    .content {
        img {
            width: 100%;
        }
        padding: 16px;
        hr {
            margin: 16px 0;
        }
    }
}
.header {
    padding: 0 16px;
    div {
        font-size: 1.4rem;
    }
}
.answer-list {
    width: 100%;
    .button {
        height: auto;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }
}

.answer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    text-align: left;
    width: 561px;
    .button {
        height: auto;
        border-radius: 0;
    }
    input {
        width: 20px;
        height: 20px;
        margin: 10px;
        color: var(--primary);
    }
    div {
        padding: 5px 3px;
        font-size: 1.4rem;
        width: 100%;
        &.correct {
            background-color: green;
            color: #fff;
        }
        &.wrong {
            background-color: red;
            color: #fff;
        }
    }
}

.example-control {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
}

@media only screen and (max-width: 600px) {
    .contest {
        width: 100%;
        .content {
            padding: 10px 0;
        }
        .header {
            padding: 0;
        }
    }
    .answer-item {
        padding-left: 0;
        width: 100%;
    }
}
