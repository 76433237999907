.content-popup {
    background-color: var(--background-color);
}

.overlay {
}

.option-popup {
    color: var(--text-color);
    background-color: var(--background-color);
    .title-popup {
        display: flex;
        padding: 0;
    }
    h3 {
        margin-left: 8px;
        font-weight: 400;
    }
    h1 {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 400;
    }
}
.group-input {
    display: flex;
    input {
        width: 16px;
        height: 16px;
    }
    * {
        margin-right: 12px;
        padding: 8px 0;
    }
}
.time {
    background-color: var(--background-color);
    margin: 8px 0;
}
@media only screen and (max-width: 600px) {
    .group-input {
        flex-direction: column;
    }
}
.start-example {
    width: 100%;
    justify-content: center;
}
