.content-popup {
    width: 90%;
    height: 90%;
    background-color: var(--background-color);
}
.overlay {
    width: 100%;
    height: 100%;
}
.title {
    text-align: center;
    h1 {
        font-size: 2.6rem;
        font-weight: 500;
    }
}
.wrapper {
    background-color: var(--background-color);
    color: var(--text-color);
}
.content {
    display: flex;
    margin-top: 16px;
    height: 100%;
}
.advice {
    text-align: center;
    padding: 8px;
}
//Result
.result {
    border-right: 1px solid black;
    flex: 4;
    .image {
        text-align: center;
        width: 100%;
        .title {
            font-size: 2.6rem;
            font-weight: 600;
        }
        img {
            width: 60%;
        }
    }
}
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.score {
    margin-top: 8px;
    font-size: 2.8rem;
    font-weight: 700;
}
.rate {
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.rate-content {
    color: #fff;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 8px;
    text-align: center;
    line-height: 30px;
    display: block;
    height: 30px;

    &.perfect {
        background-color: Gold;
    }

    &.good {
        background-color: Green;
    }

    &.average {
        background-color: Blue;
    }

    &.poor {
        background-color: Orange;
    }

    &.bad {
        background-color: red;
    }
}
.detail {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-around;
}

// File example
.word_example {
    flex: 7;
    padding: 16px;
    margin-left: 8px;
    border-radius: 8px;
    border: 1px solid black;
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
}
.action {
    margin-top: 16px;
    display: flex;
    justify-content: end;
}
@media only screen and (max-width: 600px) {
    .word_example {
        display: none;
    }
    .box {
        margin-right: 0;
        width: 100vw;
    }
    .result {
        border: none;
    }
}
