.wraper {
    display: flex;
    justify-content: space-between;

    width: 100%;
}

.box {
    color: var(--text-color);
    border-radius: 16px;
    background-color: var(--background-color);
    padding: 16px;
    margin: 16px 16px 16px 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 10px 4px;
    height: auto;
}

.info-example {
    width: 286px;
    height: 292px;
}

.info-author {
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}

.name {
    margin: 0 10px;
}

.chart {
    display: flex;
    margin: 10px 0;
    * {
        font-size: 1.4rem;
    }
    div {
        display: flex;
        margin-right: 16px;
        p {
            padding-left: 3px;
        }
    }
}

.total {
    margin-top: 10px;
    p {
        font-size: 1.4rem;
        font-weight: 400;
    }
    .time {
        margin-top: 4px;
        font-size: 1.8rem;
        font-weight: 700;
    }
}

.other {
    .time {
        display: flex;
        flex-direction: column;
        margin: 8px 0;
        label {
            font-size: 1.4rem;
            font-weight: 400;
        }
        select {
            margin-top: 6px;
            width: 147px;
            height: 26px;
            outline: none;
            border-radius: 8px;
            border: 1px solid var(--color-disable);
        }
    }
}

.other-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    padding: 8px;
    div {
        display: flex;
    }
}
// Part action
.part-example {
    h1 {
        margin-bottom: 16px;
    }
    .content {
        max-height: 140px;
        overflow: hidden;
        overflow-y: auto;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .button {
        margin: 8px 0;
        &.active {
            color: black;
            background-color: var(--color-disable);
            p {
                color: black;
            }
        }
        h1 {
            text-align: left;
            font-size: 1.4rem;
            font-weight: 400;
        }
        border-radius: 0;
        p {
            font-size: 1.2rem;
            color: var(--text-color);
        }
        width: 100%;
        height: 67px;
        justify-content: flex-start;
        svg {
            font-size: 2rem;
            padding: 10px 8.8px;
            border-radius: 50%;
            color: #fff;
            background-color: rgb(233, 30, 99);
        }
    }
    .result {
        display: flex;
        justify-content: center;
        div {
            display: flex;
            margin: 8px;
        }
        p {
            font-size: 1.4rem;
            font-weight: 400;
        }
    }
    .wrong,
    .correct {
        margin: 0 5px;
    }
    .wrong {
        color: red;
    }
    .correct {
        color: green;
    }
}

//contest

// Mục lục
.example-table {
    width: 315px;
    height: 450px;
    .content {
        max-height: 352px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 0 16px 0px 16px;
        overflow: hidden;
        overflow-y: scroll;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .button {
        margin: 6.4px;
        background-color: var(--color-disable);
        width: 64px;

        height: 36px;
        justify-content: center;

        color: black;
        &.wrong {
            background-color: red;
            color: #fff;
        }
        &.correct {
            background-color: green;
            color: #fff;
        }
        &.active {
            background-color: var(--primary);
            color: #fff;
        }
    }
    .retry {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .wraper {
        flex-direction: column;
        padding: 0;
    }
    .box {
        margin-right: 0;
        width: 100vw;
    }
}
