.wraper {
    position: fixed;
    left: 0;
    top: 70px;
    width: 280px;
    height: calc(100vh - 70px);
    padding: 8px 8px;
    z-index: 999;
    background-color: var(--background-color);
    border-right: 1px solid var(--color-disable);
    color: var(--text-color);
    transition: all ease 0.5s;

    overflow: hidden;
    &.active {
        transform: translateX(0);
    }
    .button {
        width: 264px;
    }
    &.hide {
        width: 70px;
        .button {
            width: auto;
        }
        &:hover {
            width: 280px;
            .button {
                width: 264px;
            }
            .button > span {
                display: block;
            }
        }
        .button > span {
            display: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .wraper {
        transform: translateX(-280px);
    }
}
