.filter {
    display: flex;
}
.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 10px 4px;
    background-color: var(--background-color);
}
.page {
    margin: 0 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .group-page {
        display: flex;
        // width: 700px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}
