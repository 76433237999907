.answer-list {
    width: 100%;
    .button {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }
}
.button {
    display: block;
    height: auto;
    border-radius: 0;
}
.answer-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
    text-align: left;
    width: 561px;

    input {
        width: 20px;
        height: 20px;
        margin: 10px;
        color: var(--primary);
    }
    div {
        padding: 5px 3px;
        font-size: 1.4rem;
        width: 100%;
        &.correct {
            background-color: green;
            color: #fff;
        }
        &.wrong {
            background-color: red;
            color: #fff;
        }
    }
    .answer-content {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .answer-item {
        padding-left: 0;
        width: 100%;
    }
}
